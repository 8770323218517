<template>
    <div>
        <div v-for="(nav, index) in navigations" :key="index" class="mb-3 px-0">
            <router-link :to="{name: nav.path.name}" custom v-slot="{ navigate }">
                <div @click="navigate" :class="{active: isActive(nav.path.url)}" class="row align-items-center navigator">
                    <div class="col d-flex align-items-center pe-0">
                        <i :class="nav.icon"></i>
                    </div>
                    <div class="col-10 text-capitalize">
                        {{$t("nav", {en: nav.title.en, th: nav.title.th})}}
                    </div>
                </div>
            </router-link>
            <div v-for="(sub, subIndex) in nav.subNavs" :key="subIndex" class="my-2">
                <router-link :to="{name: sub.path.name}" custom v-slot="{ navigate }">
                    <div @click="navigate" :class="{active: isActive(`${nav.path.url}/${sub.path.url}`)}" class="row justify-content-end small navigator">
                        <div class="col-10 text-capitalize">
                            — {{$t("subNav", {en: sub.title.en, th: sub.title.th})}}
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="pt-4 px-0 mt-4 border-top border-secondary">
            <router-link :to="{name: 'Homepage'}" custom v-slot="{ navigate }">
                <div @click="navigate" class="row align-items-center navigator">
                    <div class="col-2 d-flex align-items-center pe-0">
                        <i class="fas fa-sign-out-alt"></i>
                    </div>
                    <div class="col text-capitalize">{{$t(`exit`)}}</div>
                </div>
            </router-link>
        </div>
    </div>
</template>
<style scoped>
.navigator {
    cursor: pointer;
    color: #bbb;
    transition: .25s;
}
.navigator:hover, .navigator.active {
    color: #fff;
}
</style>
<i18n>
{
    "en": {
        "nav": "{en}",
        "subNav": "{en}",
        "exit": "Back to website"
    },
    "th": {
        "nav": "{th}",
        "subNav": "{th}",
        "exit": "กลับสู่หน้าหลัก"
    }
}
</i18n>
<script>
import navigations from '@/assets/json/navigations.json'
export default {
    data() {
        return {
            navigations: navigations.admin
        }
    },
    methods: {
        isActive(url) {
            url = `/admin/${url}`

            const lastIndex = url.length - 1
            if (url[lastIndex] === '/') url = url.substring(0, lastIndex)

            let sameAsPath = (url === this.$route.path)
            return sameAsPath
        }
    }
}
</script>