<template>
    <div>
        <div class="container-fluid panel d-flex flex-column">
            <div class="row align-items-center">
                <div class="col-2 p-3 text-center">
                    <SiteLogo />
                </div>
                <div class="col-10 container d-flex">
                    <div class="flex-grow-1"></div>
                    <div class="text-end">
                        <LanguageSelector />
                    </div>
                </div>
            </div>
            <div class="row flex-grow-1">
                <div class="col-2 p-3">
                    <Navigator />
                </div>
                <div class="col-10 container bg-light p-3">
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.panel {
    min-height: 100vh;
}
.row > .col-2 {
    background: #061E47;
    color: white;
}
</style>
<script>
import Navigator from './components/Admin/Navigator.vue'
export default {
    components: {
        Navigator
    }
}
</script>